var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{attrs:{"fixed":"","bottom":"","right":""},model:{value:(_vm.speedDeal),callback:function ($$v) {_vm.speedDeal=$$v},expression:"speedDeal"}},[_c('v-btn',{staticClass:"red darken-2",attrs:{"slot":"activator","dark":"","fab":"","hover":""},slot:"activator",model:{value:(_vm.speedDeal),callback:function ($$v) {_vm.speedDeal=$$v},expression:"speedDeal"}},[(_vm.speedDeal)?_c('v-icon',[_vm._v("close")]):_c('v-icon',[_vm._v("add")])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"orange",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleGame($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("arrow_back")])],1)]}}])},[_c('span',[_vm._v("Back to lobby")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"green",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.addNewDeck($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("filter_none")])],1)]}}])},[_c('span',[_vm._v("Add new deck")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"indigo",attrs:{"fab":"","dark":"","small":""},nativeOn:{"click":function($event){return _vm.addNewCounter($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("plus_one")])],1)]}}])},[_c('span',[_vm._v("Add a counter")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"red",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.rollDice($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("casino")])],1)]}}])},[_c('span',[_vm._v("Roll a dice")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.rules || false)?_c('v-btn',_vm._g(_vm._b({staticClass:"orange",attrs:{"fab":"","dark":"","small":"","href":_vm.rules,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("description")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Rules")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"blue",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openChat($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("chat")])],1)]}}])},[_c('span',[_vm._v("Chat & Settings")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user !== null && _vm.lobbyAdmin === _vm.user.nickname)?_c('v-btn',_vm._g(_vm._b({staticClass:"gray",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.lobbySettings($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("settings")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Lobby settings")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }