var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tabletop",staticClass:"tabletop",style:(_vm.tabletopStyle)},[_vm._l((_vm.game.objects),function(object,i){return [(object !== undefined && object.type === 'card')?_c('div',{key:i,staticClass:"card",class:{
        inhand: object.hand === _vm.user.nickname,
        pinned: !!object.pin,
        draggable: !object.pin,
      },style:({
        transform:
          'translate(' +
          object.x +
          'px, ' +
          object.y +
          'px) rotate(' +
          (!!object.hand && object.hand !== _vm.user.nickname ? -1 : 1) *
            object.rotation +
          'deg)',
      }),attrs:{"data-id":i},on:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu('card', object.x, object.y, i)},"dblclick":function($event){return _vm.cardPreviewOpen(i)},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }$event.preventDefault();return _vm.cardMiddleClick($event, i)}}},[_c('img',{style:({
          width: !object.real_size
            ? (object.size === undefined ? 111 : (925 * object.size) / 100) +
              'px'
            : 'initial',
        }),attrs:{"alt":"card","src":!object.hand || object.hand === _vm.user.nickname
            ? object.url
            : object.back}})]):(object && object.type === 'deck')?_c('div',{key:i,staticClass:"draggable deck",class:{ empty_deck: object.cards.length <= 0 },style:({
        transform:
          'translate(' +
          object.x +
          'px, ' +
          object.y +
          'px) rotate(' +
          (object.rotation || 0) +
          'deg)',
        backgroundColor: object.color,
        width: object.cards.length <= 0 ? '111px' : 'initial',
      }),attrs:{"data-id":i},on:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu('deck', object.x, object.y, i)},"dblclick":function($event){return _vm.takeCard(i)}}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"header",style:({ fontSize: (object.text.length > 8 ? '0.9' : '1') + 'em' })},[_vm._v(" "+_vm._s(object.text)+" ")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(object.cards.length))])]),(object.cards.length > 0)?_c('img',{style:({
          width: !object.cards[0].real_size
            ? (object.cards[0].size === undefined
                ? 111
                : (925 * object.cards[0].size) / 100) + 'px'
            : 'initial',
        }),attrs:{"alt":"deck","src":object.fliped ? object.cards[0].url : object.cards[0].back}}):_vm._e()]):(object !== undefined && object.type === 'counter')?_c('v-avatar',{key:i,staticClass:"draggable counter",class:[object.color],style:({
        transform: 'translate(' + object.x + 'px, ' + object.y + 'px)',
      }),attrs:{"data-id":i},on:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu('counter', object.x, object.y, i)}}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(object.count))])]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }